export enum ProtectedEndPoints {
	GetDashboardDetails = "dashboard",
	GetViewCaseInfo = "dashboard/view-case",
	GetViewNoteInfo = "dashboard/get-view-notes",
	SaveViewNotes = "dashboard/view-notes",
	GetCancelCaseInfo = "dashboard/get-cancel-case",
	GetAssignCaseInfo = "dashboard/get-assign-case",
	AssignCase = "dashboard/assign-case",
	CancelCase = "dashboard/cancel-case",
	BlockCase = "dashboard/block-case",
	SendLink = "dashboard/send-link",
	GetGridRoles = "role/grid",
	GetRoleDetails = "role/get-role",
	CreateRole = "role/create",
	UpdateRole = "role/update",
	DeleteRole = "role/delete/",
	GetGridVendors = "vendor/grid",
	AddVendor = "vendor/create",
	UpdateVendor = "vendor/update",
	GetAllRoles = "role/get-all",
	GetNewRoleDetails = "role/get-create-role",
	GetAddVendorDetails = "vendor/get-create-vendor",
	GetVendorDetails = "vendor/get-vendor",
	DeleteVendor = "vendor/delete/",
	GetGridProfessions = "profession/grid",
	CreateProfession = "profession/create",
	UpdateProfession = "profession/update",
	DeleteProfession = "profession/delete",
	GetHistorySearchFields = "case-history/get-search-field",
	GetHistoryGrids = "case-history/case-history-grid",
	CreateRequest = "request/create-request",
	SendEmail = "dashboard/send-email",
	ExportAll = "dashboard/export-all-case",
	getChatHistory = "chat-hub/getChatHistory",
	getMessages = "chat-hub/get-messages",
	acceptCase = "dashboard/accept-case",
	getPhysicianListByRegion = "physician/get-physician-list-by-region",
	getViewUploadsInfo = "viewUploads/get-view-uploads",
	uploadFiles = "viewUploads/upload-files",
	deleteFiles = "viewUploads/deletefiles",
	sendFiles = "viewUploads/send-email-files",
	downloadMultipleFiles = "viewUploads/download-mulitple-files",
	downloadFiles = "viewUploads/downloadfiles",
	uploadAudioVideoForChat = "chat/uploadAudioVideoForChat",
	GetSendOrderInfo = "send-order/get-send-order-info",
	FillPDFToSendOrder = "send-order/fill-order-pdf",
	SaveOrderDetails = "send-order/save-order-details",
	GetRecommendationForm = "doctors-note/get-recommendation-form",
	SaveRecommendationForm = "doctors-note/save-recommendation-form",
	SendMailForRecommendationForm = "doctors-note/send-recommendation-mail",
	GetEncounterFormInfo = "encounter-form/get-encounter-form-info",
	SaveEncounterForm = "encounter-form/save-encounter-form",
	SendMailForEncounterForm = "encounter-form/send-encounter-form-mail",
	SaveCallTypeForRequest = "request/save-call-type",
	RegenerateRecommendation = "doctors-note/regenerate-recommendation",
	GetConcludeCareInfo = "conclude-care/get-conclude-care-info",
	ConfirmConcludeCare = "conclude-care/confirm-conclude-care",
	GetCloseCaseInfo = "close-case/get-close-case-info",
	UpdateEmailAndPhone = "close-case/update-email-phone",
	GenerateInvoice = "close-case/generate-invoice",
	SendAgreement = "dashboard/send-agreement",
	ClearCase = "dashboard/clear-case",
	GetShifts = "scheduling/get-shifts",
	SaveShift = "scheduling/save-shift",
	GetPhysicianListByRegionIds = "scheduling/get-physician-by-regions",
	ChangeShiftStatus = "scheduling/change-shift-status",
	DeleteShift = "scheduling/delete-shift",
	GetMDOnCall = "scheduling/get-md-on-call",
	GetRequestedShiftsGrid = "scheduling/get-requested-shifts-grid",
	ApproveRequestedShifts = "scheduling/approve-requested-shifts",
	DeleteRequestedShifts = "scheduling/delete-requested-shifts",
	GetEmailLogs = "logs/get-email-logs",
	GetSMSLogs = "logs/get-sms-logs",
	GetPhysicianShiftHistory = "scheduling/get-physician-shift-history",
	ProviderImageUpload = "my-profile/provider-image-upload",
	ViewDownloadFile = "my-profile/view-download-file",
	GetBusinessByProfession = "send-order/get-business-by-profession",
	DeletePhysician = "physician/delete-physician",
	GetProviderPayrate = "physician/get-provider-payrate",
	SaveProviderPayrate = "physician/save-provider-payrate",
	GetFreshUsername = "general/get-fresh-username",
	GetRequestDataGrid = "case-history/get-request-data-grid",
	GetPatientRecordsGrid = "case-history/get-patient-records",
	DeleteRequestData = "request/delete",
	GetPatientDashBoardGrid = "case-history/get-patient-dashboard-grid",
	GetBlockHistoryGrid = "case-history/get-block-history-grid",
	UnblockRequest = "request/unblock-request",
	GetHaloAdministratorsGrid = "halo/halo-administrators-grid",
	CreateHaloAdministrator = "halo/halo-administrators-create",
	UpdateHaloAdministrator = "halo/halo-administrators-update",
	DeleteHaloAdministrator = "halo/halo-administrators-delete",
	GetHaloRequestGrid = "halo/get-halo-request-grid",
	GetHaloEmployeeGrid = "halo/get-halo-employee-grid",
	GetHaloEmployee = "halo/get-halo-employee",
	CreateHaloEmployee = "halo/create-halo-employee",
	UpdateHaloEmployee = "halo/update-halo-employee",
	DeleteHaloEmployee = "halo/delete-halo-employee",
	GetHaloWorkPlaceGrid = "halo/halo-workplace-grid",
	CreateUpdateHaloWorkPlace = "halo/create-update-halo-workplace",
	DeleteHaloWorkPlace = "halo/delete-halo-workplace",
	GetTimeSheetGrid = "timesheet/get-timesheet-grid",
	GetDailyTimeSheetDetails = "timesheet/get-daily-timesheet-details",
	UploadFileForOtherReimbursement = "timesheet/upload-file-for-other-reimbursement",
	EditTimesheetReimbursement = "timesheet/edit-timesheet-reimbursement",
	DeleteReimbursement = "timesheet/delete-reimbursement",
	SubmitTimesheetData = "timesheet/submit-timsheet-data",
	GetBiWeeklyTimeSheetDetail = "timesheet/get-bi-weekly-timesheet-detail",
	FinalizeBiWeeklyTimesheet = "timesheet/finalize-bi-weekly-timesheet",
	ApproveTimesheet = "timesheet/approve-timesheet",
	GetProviderLocation = "physician/get-provider-location",
	ExportAllCases = "case-history/export-all-cases",
	DeleteMultipleRequests = "request/delete-multiple-requests",
	GetEditAdminProfile = "admin/get-edit-admin-profile",
	SaveAdminProfile = "admin/save-admin-profile",
	DeleteAdminAccount = "admin/delete-admin-account",
	UpdateProviderLocation = "physician/update-provider-location",
	ChangeLocationPreference = "physician/change-location-preference",

	// WhatsApp
	InitiateChat = "whats-app/initiate-chat",
	GetMyProfile = "myprofile/get-myprofile",
	ResetPasswordFromMyProfile = "myprofile/reset-password",
	UpdateMyProfile = "myprofile/update",
	GetRegionInfo = "region/get-region-info",
	SendRequestDTYSupport = "region/send-request-dty-support",
	EditRequest = "region/edit-request",
	GetLoggedInUserId = "general/get-logged-in-userid",
	GetPhysicianDropDownList = "general/get-physician-drop-down",
	GetProvidersInfo = "physician/get-providers-info",
	GetEditProviderProfile = "physician/get-edit-provider",
	UpdateProviderProfile = "physician/update-provider-profile",
	SendMsgProvider = "physician/send-msg-provider",
	RequestToAdmin = "my-profile/send-request-to-admin",
	ChangeProviderNotifications = "physician/change-provider-notification",
	GetAccountsGrid = "accounts/get-accounts-grid",
	GetTimesheetPeriodList = "timesheet/get-timesheetperiod-drop-down",
	SkipAgreement = "dashboard/skip-agreement",
	SendDefaultMessage = "dashboard/send-message",
}

export enum UnProtectedEndPoints {
	GenerateDefaultToken = "authorize/generate-default-token",
	Login = "authorize/login",
	SSOLogin = "authorize/sso",
	ForgotPassword = "authorize/forgot-password",
	ResetPasswordVerifyLink = "authorize/reset-password-verify-link",
	ResetPassword = "authorize/reset-password",
	getRegions = "request/get-regions",
	GetAllZipCodes = "general/get-all-zipcodes",
}
