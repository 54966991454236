export enum RequestStatus {
	Unassigned = 1,
	Accepted = 2,
	Cancelled = 3,
	Reserving = 4,
	MDEnRoute = 5,
	MDOnSite = 6,
	FollowUp = 7,
	Closed = 8,
	Locked = 9,
	Rejected = 10,
	Consult = 11,
	Clear = 12,
	CancelledByProvider = 13,
	CCUploadedByClient = 14,
	CCApprovedByAdmin = 15,
	NotReserved = 16,
	Conclude = 17,
	DeclinedByTransaction = 18,
	SettleOffline = 19,
	Deleted = 20,
	CloseCancelled = 21,
	CancelledByPatient = 25,
}

export enum RequestTypes {
	All = 0,
	Business = 1,
	Patient = 2,
	Family = 3,
	Concierge = 4,
	Halo = 5,
	VIP = 6,
	// Declined = 7,
}
// TODO: need change here
export enum InformationTabTypes {
	New = 1,
	Closed = 4,
	Active = 5,
	Pending = 2,
	Conclude = 7,
	UnPaid = 24,
}

export enum AccountType {
	All = 0,
	Admin = 1,
	Physician = 2,
	Business = 3,
	HaloAdministrator = 4,
	Patient = 5,
	PatientAccount = 6,
	VIP = 7,
	Groups = 8,
}

export enum ProfessionStatus {
	InActive = 0,
	Active = 1,
}
