import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { ArrowBack } from "assests/images";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { createCommonAPICall } from "utility/helpers";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { addVendorSchema } from "utility/validations";
import { IAddUpdateBusiness } from "utility/interfaces";
import vendorService from "services/vendor-service";
import { AppRoutings } from "utility/enums/app-routings";
import { CustomIntlTelInput } from "components";
import { CountryData } from "react-intl-tel-input-18";

type ComponentProps = {
	formValues: IAddUpdateBusiness;
	setFormValues: any;
	title: string;
	renderer: number;
};

export function AddBusinessForm(props: ComponentProps) {
	// Extract props
	const { formValues, setFormValues, title, renderer } = props;

	//Page level local variable
	const navigate = useNavigate();
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// State variables
	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
	const [isSecondaryNumberValid, setIsSecondaryNumberValid] = useState(true);

	// handled events and functions
	const handleOnClickBackCancel = () => navigate(-1);
	const handleOnclickSave = async () => {
		const requestBody = { ...formValues };

		const data = await createCommonAPICall({
			requestBody: requestBody,
			apiService:
				requestBody.vendorId && requestBody.vendorId > 0
					? vendorService.updateVendor
					: vendorService.addVendor,
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			navigate(AppRoutings.Vendors);
		}
	};
	const handleOnChangeFormValue = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
	};

	const handlePhoneNumberChange = (
		isValid: boolean,
		phoneNumber: string,
		countryData: CountryData,
		isPhoneNumber: boolean
	) => {
		if (isPhoneNumber) {
			setIsPhoneNumberValid(isValid);
		} else {
			if (!isValid && phoneNumber === "") {
				setIsSecondaryNumberValid(true);
			} else {
				setIsSecondaryNumberValid(isValid);
			}
		}

		let formattedPhoneNumber =
			phoneNumber != ""
				? phoneNumber.includes("+")
					? phoneNumber
					: `+${countryData.dialCode} ${phoneNumber}`
				: "";

		if (isPhoneNumber) {
			setFormValues({ ...formValues, phoneNumber: formattedPhoneNumber });
		} else {
			setFormValues({ ...formValues, secondaryNumber: formattedPhoneNumber });
		}
	};

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm<IAddUpdateBusiness>({
		resolver: yupResolver(addVendorSchema),
	});

	// useEffects
	useEffect(() => {
		reset();
	}, []);

	useEffect(() => {
		Object.entries(formValues).forEach((child) => {
			setValue(child[0] as any, child[1]);
		});
	}, [formValues]);

	return (
		<>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">{title}</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						<Typography variant="h4">Submit Information</Typography>
						<div>
							<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
								<Grid item xs={12} sm={6}>
									<TextField
										error={errors?.vendorName?.message ? true : false}
										{...register("vendorName")}
										fullWidth
										id="vendorName"
										label="Business Name"
										variant="outlined"
										name="vendorName"
										autoFocus
										value={formValues?.vendorName || ""}
										helperText={errors?.vendorName?.message?.toString()}
										onChange={handleOnChangeFormValue}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormControl fullWidth className="select-input">
										<InputLabel id="status-label">Profession</InputLabel>
										<Select
											{...register("profession")}
											error={errors.profession?.message ? true : false}
											labelId="ProfessionTypes"
											id="professionTypes"
											value={formValues.profession}
											label="Profession"
											name="profession"
											onChange={(e) => {
												setFormValues({
													...formValues,
													[e.target.name]:
														typeof e.target.value == "string"
															? parseInt(e.target.value)
															: e.target.value,
												});
											}}
											MenuProps={{
												className: "select-input-modal custom-select-dropdown",
											}}
										>
											{formValues?.professionTypes?.map((type, index) => {
												return (
													<MenuItem key={index} value={type.value}>
														{type.text}
													</MenuItem>
												);
											})}
										</Select>
										<FormHelperText error>
											{errors.profession?.message?.toString()}
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										error={errors?.faxNumber?.message ? true : false}
										{...register("faxNumber")}
										fullWidth
										id="faxNumber"
										label="Fax Number"
										variant="outlined"
										name="faxNumber"
										value={formValues?.faxNumber || ""}
										helperText={errors?.faxNumber?.message?.toString()}
										onChange={handleOnChangeFormValue}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									{renderer > 0 && (
										<CustomIntlTelInput
											isPhoneNumberValid={isPhoneNumberValid}
											onBlur={() => {}}
											onChange={(
												isValid: boolean,
												phoneNumber: string,
												countryData: CountryData
											) => {
												handlePhoneNumberChange(
													isValid,
													phoneNumber,
													countryData,
													true
												);
											}}
											value={formValues?.phoneNumber || ""}
											placeHolder="Phone Number"
										/>
									)}
									{/* <TextField
										error={errors?.phoneNumber?.message ? true : false}
										{...register("phoneNumber")}
										fullWidth
										id="phoneNumber"
										label="Phone Number"
										variant="outlined"
										name="phoneNumber"
										value={formValues?.phoneNumber || ""}
										helperText={errors?.phoneNumber?.message?.toString()}
										onChange={handleOnChangeFormValue}
									/> */}
								</Grid>
								<Grid item xs={12} sm={6}>
									{renderer > 0 && (
										<CustomIntlTelInput
											isPhoneNumberValid={isSecondaryNumberValid}
											onBlur={() => {}}
											onChange={(
												isValid: boolean,
												phoneNumber: string,
												countryData: CountryData
											) => {
												handlePhoneNumberChange(
													isValid,
													phoneNumber,
													countryData,
													false
												);
											}}
											value={formValues?.secondaryNumber || ""}
											placeHolder="Secondary Number"
											isOptional={true}
										/>
									)}
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										error={errors?.email?.message ? true : false}
										{...register("email")}
										fullWidth
										id="email"
										label="Email"
										variant="outlined"
										name="email"
										value={formValues?.email || ""}
										helperText={errors?.email?.message?.toString()}
										onChange={handleOnChangeFormValue}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										id="businessContact"
										label="Business Contact"
										variant="outlined"
										name="businessContact"
										value={formValues?.businessContact || ""}
										onChange={handleOnChangeFormValue}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										id="street"
										label="Street"
										variant="outlined"
										name="address"
										value={formValues?.address || ""}
										onChange={handleOnChangeFormValue}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										id="city"
										label="City"
										variant="outlined"
										name="city"
										value={formValues?.city || ""}
										onChange={handleOnChangeFormValue}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										id="state"
										label="State"
										variant="outlined"
										name="state"
										value={formValues?.state}
										onChange={handleOnChangeFormValue}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										id="zip"
										label="Zip/postal"
										variant="outlined"
										name="zip"
										value={formValues?.zip || ""}
										onChange={handleOnChangeFormValue}
									/>
								</Grid>
							</Grid>
						</div>
						<div className="request-btn-group">
							<Button
								onClick={() => {
									if (
										formValues.phoneNumber === "" ||
										!isPhoneNumberValid ||
										!isSecondaryNumberValid
									) {
										if (formValues.phoneNumber === "")
											setIsPhoneNumberValid(false);

										handleSubmit(() => {})();
									} else {
										handleSubmit(handleOnclickSave)();
									}
								}}
								variant="contained"
							>
								Save
							</Button>
							<Button variant="outlined" onClick={handleOnClickBackCancel}>
								Cancel
							</Button>
						</div>
					</Box>
				</Box>
			</main>
		</>
	);
}
