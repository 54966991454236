import React, { useContext, useState } from "react";
import myCustomTheme from "app.theme";
import { useNavigate } from "react-router-dom";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { AddIcon, ArrowBack } from "assests/images";
import {
	IProfessionInfo,
	IProfessions,
	ISearchCriteria,
} from "utility/interfaces";
import { ProfessionInfoTable, CreateProfessionModal } from "components";
import { Constants } from "utility/enums/constants";
import vendorService from "services/vendor-service";
import { createCommonAPICall } from "utility/helpers";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { SortOrder } from "utility/enums/sort-order";

const ProfessionInfo: React.FC = () => {
	// Page level variable
	const navigate = useNavigate();
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [isOpenCreateProfession, setIsOpenCreateProfession] = useState(false);
	const [formValues, setFormValues] = useState<IProfessionInfo>({
		professionName: "",
		status: "active",
		healthProfessionalId: 0,
	});

	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		PageIndexId: 0,
		PageSize: Constants.DefaultTablePageSize.valueOf(),
		SortOrder: SortOrder.descending,
		SortBy: "",
		SearchBy: "",
	});
	const [professionPageInfo, setProfessionPageInfo] = useState<IProfessions>({
		professions: [],
		totalRecords: 0,
	});

	// handled events or functions
	const getProfessionGrids = async () => {
		const data = await createCommonAPICall({
			requestBody: searchCriteria,
			apiService: vendorService.getGridProfessions,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.data) {
			console.log(data.data);
			setProfessionPageInfo({
				professions: data.data.profession,
				totalRecords: data.data.totalRecords,
			});
		}
	};

	const handleOnChangeFormValue = (e: any) => {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};
	const OnClickCreateProfession = () => {
		setFormValues({
			healthProfessionalId: 0,
			professionName: "",
			status: "Active",
		});
		setIsOpenCreateProfession(true);
	};
	const OnClickEditProfession = (initialFormValue: IProfessionInfo) => {
		console.log("initialFormValue: ", initialFormValue);
		setFormValues({
			professionName: initialFormValue.professionName,
			status: initialFormValue.status,
			healthProfessionalId: initialFormValue.healthProfessionalId,
		});
		setIsOpenCreateProfession(true);
	};

	return (
		<>
			<Box>
				<main className="main-content">
					<div
						className="overlay"
						onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="request-container-box">
						<div className="request-header">
							<Typography variant="h2">Profession(s)</Typography>
							<Button variant="outlined" onClick={() => navigate(-1)}>
								<img src={ArrowBack} alt="arrow" />
								Back
							</Button>
						</div>
						<Box className="request-box">
							<div className="attachment">
								<Box
									className="selected-action-btn"
									sx={{ justifyContent: "flex-end", width: "100%" }}
								>
									<Tooltip title="Create Profession">
										<Button
											variant="outlined"
											disableElevation
											disableRipple
											disableFocusRipple
											className="min-w-111px min-w-sm-0"
											onClick={OnClickCreateProfession}
										>
											<img src={AddIcon} alt="download" />
											<span>Create</span>
										</Button>
									</Tooltip>
								</Box>
							</div>
							<ProfessionInfoTable
								handleOnClickEdit={OnClickEditProfession}
								searchCriteria={searchCriteria}
								setSearchCriteria={setSearchCriteria}
								professionPageInfo={professionPageInfo}
								setProfessionPageInfo={setProfessionPageInfo}
								getProfessionGrids={getProfessionGrids}
							/>
						</Box>
					</Box>
				</main>
			</Box>
			<CreateProfessionModal
				isModelOpen={isOpenCreateProfession}
				handleOnModalClose={() => setIsOpenCreateProfession(false)}
				formValues={formValues}
				handleOnChangeFormValue={handleOnChangeFormValue}
				getProfessionGrids={getProfessionGrids}
			/>
		</>
	);
};

export default ProfessionInfo;
