import { AxiosResponse } from "axios";
import {
	IProfession,
	IVendorDetails,
	IVendorInfo,
	IHttpsResponse,
	IAddUpdateBusiness,
	ISearchCriteria,
	IProfessionInfo,
} from "utility/interfaces";
import { ProtectedEndPoints } from "./api-end-points";
import httpClient from "./base-service";

const getGridVendors = async (
	searchCriteria: ISearchCriteria
): Promise<AxiosResponse<IHttpsResponse<IVendorInfo>>> =>
	httpClient.post<IHttpsResponse<IVendorInfo>>(
		ProtectedEndPoints.GetGridVendors,
		searchCriteria
	);

const addVendor = async (
	request: IAddUpdateBusiness
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.AddVendor, request);

const updateVendor = async (
	request: IAddUpdateBusiness
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.UpdateVendor,
		request
	);

const getAddVendorDetails = async (): Promise<
	AxiosResponse<IHttpsResponse<IProfession[]>>
> =>
	httpClient.get<IHttpsResponse<IProfession[]>>(
		ProtectedEndPoints.GetAddVendorDetails
	);

const getVendorDetails = async (
	vendorId: string
): Promise<AxiosResponse<IHttpsResponse<IVendorDetails>>> =>
	httpClient.post<IHttpsResponse<IVendorDetails>>(
		ProtectedEndPoints.GetVendorDetails,
		{
			vendorId,
		}
	);

const deleteVendor = async (
	vendorId: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.DeleteVendor, {
		vendorId,
	});

const getGridProfessions = async (
	searchCriteria: ISearchCriteria
): Promise<AxiosResponse<IHttpsResponse<IProfessionInfo>>> =>
	httpClient.post<IHttpsResponse<IProfessionInfo>>(
		ProtectedEndPoints.GetGridProfessions,
		searchCriteria
	);

const createProfession = async (
	request: IProfessionInfo
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.CreateProfession,
		request
	);

const updateProfession = async (
	request: IProfessionInfo
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.UpdateProfession,
		request
	);

const deleteProfession = async (
	healthProfessionalId: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.DeleteProfession, {
		healthProfessionalId,
	});

export default {
	getGridVendors,
	addVendor,
	updateVendor,
	getAddVendorDetails,
	getVendorDetails,
	deleteVendor,
	getGridProfessions,
	createProfession,
	updateProfession,
	deleteProfession,
};
