import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import vendorService from "services/vendor-service";
import { ProfessionStatus } from "utility/enums/request-status";
import { commonModalStyle, createCommonAPICall } from "utility/helpers";
import {
	IProfessionInfo,
	ICreateUpdateProfessionRequest,
} from "utility/interfaces";
import { createProfession } from "utility/validations";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";

type ComponentProps = {
	isModelOpen: boolean;
	handleOnModalClose: any;
	formValues: IProfessionInfo;
	handleOnChangeFormValue: any;
	getProfessionGrids: any;
};

export function CreateProfessionModal(props: ComponentProps) {
	// Extract Props
	const {
		isModelOpen,
		handleOnModalClose,
		formValues,
		handleOnChangeFormValue,
		getProfessionGrids,
	} = props;

	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
	} = useForm({
		resolver: yupResolver(createProfession),
	});

	// Events and functions
	const handleOnClickCreateOrUpdate = async () => {
		const request: ICreateUpdateProfessionRequest = {
			healthProfessionalId: formValues.healthProfessionalId ?? 0,
			professionName: formValues.professionName,
			isActive:
				formValues.status == ProfessionStatus[ProfessionStatus.Active]
					? true
					: false,
		};

		const data = await createCommonAPICall({
			requestBody: request,
			apiService:
				formValues.healthProfessionalId == 0
					? vendorService.createProfession
					: vendorService.updateProfession,
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext: setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			getProfessionGrids();
			handleOnModalClose();
		}
	};

	// useEffects
	useEffect(() => {
		reset();
		clearErrors();
	}, [isModelOpen]);

	return (
		<>
			<Modal
				open={isModelOpen}
				onClose={handleOnModalClose}
				className="send-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">
							{formValues.healthProfessionalId == 0 ? "Create" : "Update"}{" "}
							Profession
						</Typography>
						<IconButton onClick={handleOnModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<TextField
								{...register("professionName")}
								id="block"
								label="Profession Name"
								value={formValues.professionName}
								name="professionName"
								onChange={handleOnChangeFormValue}
								fullWidth
								error={errors?.professionName?.message ? true : false}
								helperText={errors?.professionName?.message?.toString()}
							/>
							<FormControl fullWidth>
								<InputLabel id="reason-label">Select Status </InputLabel>
								<Select
									labelId="region-label"
									id="region"
									name="status"
									value={formValues.status}
									label="Select Status"
									onChange={handleOnChangeFormValue}
									MenuProps={{
										className: "select-input-modal",
									}}
								>
									<MenuItem value={ProfessionStatus[ProfessionStatus.Active]}>
										{ProfessionStatus[ProfessionStatus.Active]}
									</MenuItem>
									<MenuItem value={ProfessionStatus[ProfessionStatus.InActive]}>
										{ProfessionStatus[ProfessionStatus.InActive]}
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className="modal-footer">
							<Tooltip title="Create">
								<Button
									variant="contained"
									onClick={handleSubmit(handleOnClickCreateOrUpdate)}
								>
									{formValues.healthProfessionalId ? " Update" : "Create"}
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button variant="outlined" onClick={handleOnModalClose}>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
