import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Tooltip,
	Button,
	TextField,
	FormControl,
	Select,
	MenuItem,
	Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Constants } from "utility/enums/constants";
import {
	IVendor,
	IVendorInfo,
	IHttpsResponse,
	ISearchCriteria,
	IVendorInfoSearchCriteria,
} from "utility/interfaces";
import vendorService from "services/vendor-service";
import myCustomTheme from "app.theme";
import { AppRoutings } from "utility/enums/app-routings";
import { AddIcon } from "assests/images";
import { useNavigate } from "react-router-dom";
import { createCommonAPICall } from "utility/helpers";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { SortOrder } from "utility/enums/sort-order";
import { VendorTableRow, TablePagination } from "components";

export function VendorTable() {
	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const navigate = useNavigate();

	// useStates
	const [searchCriteria, setSearchCriteria] =
		useState<IVendorInfoSearchCriteria>({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.descending,
			SortBy: "",
			SearchBy: "",
			Profession: 0,
		});
	const [vendorPageInfo, setVendorPageInfo] = useState<IVendorInfo>({
		vendors: [],
		totalRecords: 10,
		professions: [],
	});
	const [searchText, setSearchText] = useState("");

	// Handled events and functions
	const handleOnPageNumberChange = (value: number) => {
		setSearchCriteria({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		setSearchCriteria({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	const getVenderGrids = async () => {
		const data = await createCommonAPICall({
			requestBody: searchCriteria,
			apiService: vendorService.getGridVendors,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.data) setVendorPageInfo(data.data);
	};
	const handleOnClickDelete = async (row: IVendor) => {
		const data: IHttpsResponse<any> = await createCommonAPICall({
			requestBody: row.vendorId || 0,
			apiService: vendorService.deleteVendor,
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext,
			successMessage: "Record deleted successfully",
		});
		if (data && data.isSuccessfull) {
			getVenderGrids();
			// const updatedVendors = vendorPageInfo.vendors.filter(
			// 	(vendor) => vendor.vendorId !== row.vendorId
			// );
			// setVendorPageInfo({ ...vendorPageInfo, vendors: updatedVendors });
		}
	};

	const handleOnSearchTextChange = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setSearchText(e.target.value);
	};
	const handleSearchKeywordKeyUp = (event: any) => {
		// Enter keycode = 13
		if (event.keyCode === 13 || event.keyCode === 9) {
			const newSearchCriteria = {
				...searchCriteria,
				PageIndexId: 0,
				SearchBy: event.target.value.trim(),
			};
			setSearchCriteria(newSearchCriteria);
		}
	};

	// useEffects
	useEffect(() => {
		getVenderGrids();
	}, [searchCriteria]);

	return (
		<>
			<Box className="table-box">
				<div className="table-header region-table-header">
					<div className="table-input-group">
						<TextField
							id="search"
							placeholder="Search Vendors"
							variant="outlined"
							autoFocus
							onKeyDown={handleSearchKeywordKeyUp}
							value={searchText}
							onChange={handleOnSearchTextChange}
							style={{ marginRight: "15px", marginBottom: "10px" }}
						/>
						<FormControl fullWidth className="region-search">
							<Select
								labelId="region-label"
								id="region"
								value={searchCriteria.Profession}
								name="region"
								label="Search by Profession"
								fullWidth
								MenuProps={{ className: "custom-select-dropdown" }}
								onChange={(event) => {
									setSearchCriteria({
										...searchCriteria,
										PageIndexId: 0,
										PageSize: Constants.DefaultTablePageSize.valueOf(),
										Profession:
											typeof event.target.value == "number"
												? event.target.value
												: parseInt(event.target.value),
									});
								}}
							>
								{vendorPageInfo.professions.map((profession) => {
									return (
										<MenuItem key={profession.value} value={profession.value}>
											{profession.text}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
					<div className="tab-button-groups" style={{ marginLeft: "auto" }}>
						<Tooltip title="Add Business">
							<Button
								variant="outlined"
								disableElevation
								disableRipple
								disableFocusRipple
								className="min-w-111px min-w-sm-0 pl-sm-10px pr-sm-10px"
								onClick={() => navigate(AppRoutings.AddBusiness)}
							>
								<img src={AddIcon} alt="download" />
								<Box
									component="span"
									sx={{
										display: "block",
										[myCustomTheme.breakpoints.down("sm")]: {
											display: "none",
										},
									}}
								>
									Add Business
								</Box>
							</Button>
						</Tooltip>
					</div>
				</div>
				{vendorPageInfo.totalRecords > 0 ? (
					<>
						<TableContainer sx={{ display: { xs: "none", laptop: "block" } }}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell className="min-w-200">
											<TableSortLabel
												active={searchCriteria.SortBy === "Profession"}
												direction={searchCriteria.SortOrder}
												onClick={() =>
													handleOnChangeSortDirection("Profession")
												}
											>
												Profession
												<Box component="span" sx={visuallyHidden}>
													{searchCriteria.SortOrder === SortOrder.descending
														? "sorted descending"
														: "sorted ascending"}
												</Box>
											</TableSortLabel>
										</TableCell>
										<TableCell className="min-w-200">
											<TableSortLabel
												active={searchCriteria.SortBy === "VendorName"}
												direction={searchCriteria.SortOrder}
												onClick={() =>
													handleOnChangeSortDirection("VendorName")
												}
											>
												Business Name
												<Box component="span" sx={visuallyHidden}>
													{searchCriteria.SortOrder === SortOrder.descending
														? "sorted descending"
														: "sorted ascending"}
												</Box>
											</TableSortLabel>
										</TableCell>
										<TableCell className="min-w-200">
											<TableSortLabel
												active={searchCriteria.SortBy === "email"}
												direction={searchCriteria.SortOrder}
												onClick={() => handleOnChangeSortDirection("email")}
											>
												Email
												<Box component="span" sx={visuallyHidden}>
													{searchCriteria.SortOrder === SortOrder.descending
														? "sorted descending"
														: "sorted ascending"}
												</Box>
											</TableSortLabel>
										</TableCell>
										<TableCell className="min-w-200">
											<TableSortLabel
												active={searchCriteria.SortBy === "faxNumber"}
												direction={searchCriteria.SortOrder}
												onClick={() => handleOnChangeSortDirection("faxNumber")}
											>
												Fax Number
												<Box component="span" sx={visuallyHidden}>
													{searchCriteria.SortOrder === SortOrder.descending
														? "sorted descending"
														: "sorted ascending"}
												</Box>
											</TableSortLabel>
										</TableCell>
										<TableCell className="min-w-200">
											<TableSortLabel
												active={searchCriteria.SortBy === "phoneNumber"}
												direction={searchCriteria.SortOrder}
												onClick={() =>
													handleOnChangeSortDirection("phoneNumber")
												}
											>
												Phone Number
												<Box component="span" sx={visuallyHidden}>
													{searchCriteria.SortOrder === SortOrder.descending
														? "sorted descending"
														: "sorted ascending"}
												</Box>
											</TableSortLabel>
										</TableCell>
										<TableCell className="min-w-200">
											<TableSortLabel
												active={searchCriteria.SortBy === "secondaryNumber"}
												direction={searchCriteria.SortOrder}
												onClick={() =>
													handleOnChangeSortDirection("secondaryNumber")
												}
											>
												Secondary Number
												<Box component="span" sx={visuallyHidden}>
													{searchCriteria.SortOrder === SortOrder.descending
														? "sorted descending"
														: "sorted ascending"}
												</Box>
											</TableSortLabel>
										</TableCell>
										<TableCell className="min-w-200">
											<TableSortLabel
												active={searchCriteria.SortBy === "businessContact"}
												direction={searchCriteria.SortOrder}
												onClick={() =>
													handleOnChangeSortDirection("businessContact")
												}
											>
												Business Contact
												<Box component="span" sx={visuallyHidden}>
													{searchCriteria.SortOrder === SortOrder.descending
														? "sorted descending"
														: "sorted ascending"}
												</Box>
											</TableSortLabel>
										</TableCell>
										<TableCell align="center" sx={{ width: 142 }}>
											Actions
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{vendorPageInfo.vendors.map((row, index) => {
										return (
											<VendorTableRow
												key={index}
												row={row}
												handleOnClickDelete={handleOnClickDelete}
												isSmallDevice={false}
											/>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<Box
							sx={{ display: { xs: "block", laptop: "none" } }}
							className="tabledata-cards-group"
						>
							{vendorPageInfo.vendors.map((row, index) => {
								return (
									<VendorTableRow
										row={row}
										handleOnClickDelete={handleOnClickDelete}
										isSmallDevice={true}
									/>
								);
							})}
						</Box>
						<TablePagination
							currentPageNumber={searchCriteria.PageIndexId}
							handleOnPageNumberChange={handleOnPageNumberChange}
							totalRecords={vendorPageInfo?.totalRecords || 0}
							rowCount={vendorPageInfo?.vendors?.length || 0}
						/>
					</>
				) : (
					<Typography variant="h5" className="no-record" sx={{ padding: 3 }}>
						No Record(s) Found
					</Typography>
				)}
			</Box>
		</>
	);
}
