import React, { useState } from "react";
import {
	Button,
	ButtonProps,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	styled,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { WarningModal, CustomCopyTooltip } from "components";
import {
	CrossRedIcon,
	MailPrimaryIcon,
	PatientPrimaryIcon,
	PhonePrimaryIcon,
} from "assests/images";
import { useNavigate } from "react-router";
import { AppRoutings } from "utility/enums/app-routings";
import { IVendor } from "utility/interfaces";

type ComponentProps = {
	row: IVendor;
	handleOnClickDelete: any;
	isSmallDevice: boolean;
};
interface ExpandMoreProps extends ButtonProps {
	expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return (
		<Button {...other} disableFocusRipple disableElevation disableRipple />
	);
})(({ theme, expand }) => ({
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

export function VendorTableRow(props: ComponentProps) {
	// Extract Props
	const { row, handleOnClickDelete, isSmallDevice } = props;

	// Page level variable
	const navigate = useNavigate();

	// useStates
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableRow className="bg-white">
						<TableCell
							component="th"
							style={{ maxWidth: 130 }}
							scope="row"
							className="wrap min-w-130"
						>
							<CustomCopyTooltip
								canDoCopy={true}
								text="Copy"
								copyText={row.professionName}
							>
								{row.professionName}
							</CustomCopyTooltip>
						</TableCell>
						<TableCell
							component="th"
							style={{ maxWidth: 130 }}
							scope="row"
							className="wrap min-w-130"
						>
							<CustomCopyTooltip
								canDoCopy={true}
								text="Copy"
								copyText={row.vendorName}
							>
								{row.vendorName}
							</CustomCopyTooltip>
						</TableCell>
						<TableCell
							component="th"
							style={{ maxWidth: 130 }}
							scope="row"
							className="wrap min-w-130"
						>
							{row.email ? (
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.email}
								>
									{row.email}
								</CustomCopyTooltip>
							) : (
								"-"
							)}
						</TableCell>
						<TableCell
							component="th"
							style={{ maxWidth: 130 }}
							scope="row"
							className="wrap min-w-130"
						>
							<CustomCopyTooltip
								canDoCopy={true}
								text="Copy"
								copyText={row.faxNumber}
							>
								{row.faxNumber}
							</CustomCopyTooltip>
						</TableCell>
						<TableCell
							component="th"
							style={{ maxWidth: 130 }}
							scope="row"
							className="wrap min-w-130"
						>
							{row.phoneNumber ? (
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.phoneNumber}
								>
									{row.phoneNumber}
								</CustomCopyTooltip>
							) : (
								"-"
							)}
						</TableCell>
						<TableCell
							component="th"
							style={{ maxWidth: 130 }}
							scope="row"
							className="wrap min-w-130"
						>
							{row.secondaryNumber ? (
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.secondaryNumber}
								>
									{row.secondaryNumber}
								</CustomCopyTooltip>
							) : (
								"-"
							)}
						</TableCell>
						<TableCell
							component="th"
							style={{ maxWidth: 130 }}
							scope="row"
							className="wrap min-w-130"
						>
							{row.businessContact ? (
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.businessContact}
								>
									{row.businessContact}
								</CustomCopyTooltip>
							) : (
								"-"
							)}
						</TableCell>
						<TableCell align="center" component="th" scope="row">
							<span className="table-cell-title">Actions</span>
							<>
								<Tooltip title="Edit">
									<Button
										id="table-button-edit"
										variant="outlined"
										className="action-btn action-btn-primary"
										style={{ marginRight: 4 }}
										onClick={() =>
											navigate(
												AppRoutings.UpdateBusiness.replace(
													":businessId",
													row.queryString
												)
											)
										}
									>
										Edit
									</Button>
								</Tooltip>
								<Tooltip title="Delete">
									<Button
										onClick={() => setDeleteModalOpen(true)}
										variant="outlined"
										className="action-btn action-btn-primary"
										id="table-button-delete"
									>
										Delete
									</Button>
								</Tooltip>
							</>
						</TableCell>
					</TableRow>
				</>
			) : (
				<>
					<Card className="bg-white">
						<div className="card-container">
							<ExpandMore
								expand={true}
								onClick={undefined}
								aria-expanded={true}
								aria-label="show more"
							>
								<CardHeader
									title={
										<React.Fragment>
											<span>{row.vendorName}</span>
										</React.Fragment>
									}
								/>
							</ExpandMore>
							<Collapse in={true} timeout="auto" unmountOnExit>
								<CardContent>
									{row.professionName && (
										<div>
											<span>
												<img src={PatientPrimaryIcon} alt="calender" />
											</span>
											<Typography variant="h6">
												Profession:&nbsp;
												<span>{row.professionName}</span>
											</Typography>
										</div>
									)}
									{row.email && (
										<div>
											<span>
												<img src={MailPrimaryIcon} alt="calender" />
											</span>
											<Typography variant="h6">
												Email:&nbsp;
												<span>{row.email}</span>
											</Typography>
										</div>
									)}
									{row.faxNumber && (
										<div>
											<span>
												<img src={PhonePrimaryIcon} alt="calender" />
											</span>
											<Typography variant="h6">
												Fax:&nbsp;
												<span>{row.faxNumber}</span>
											</Typography>
										</div>
									)}
									{row.phoneNumber && (
										<div>
											<span>
												<img src={PhonePrimaryIcon} alt="calender" />
											</span>
											<Typography variant="h6">
												Phone Number:&nbsp;
												<span>{row.phoneNumber}</span>
											</Typography>
										</div>
									)}
									{row.businessContact && (
										<div>
											<span>
												<img src={MailPrimaryIcon} alt="calender" />
											</span>
											<Typography variant="h6">
												Business Contact:&nbsp;
												<span>{row.businessContact}</span>
											</Typography>
										</div>
									)}
									<div className="table-cell-btn-group">
										<Button
											variant="outlined"
											onClick={() =>
												navigate(
													AppRoutings.UpdateBusiness.replace(
														":businessId",
														row.queryString
													)
												)
											}
										>
											Edit
										</Button>
										<Button
											variant="outlined"
											onClick={() => setDeleteModalOpen(true)}
										>
											Delete
										</Button>
									</div>
								</CardContent>
							</Collapse>
						</div>
					</Card>
				</>
			)}
			<WarningModal
				isModalOpen={deleteModalOpen}
				handleOnClickCloseModal={() => setDeleteModalOpen(false)}
				handleOnClickOk={() => handleOnClickDelete(row)}
				warningMessage={`Are sure, you want to delete, ${row.vendorName}?`}
				title={"Delete confirmation"}
				okButtonText={"Delete"}
				closeButtonText={"Cancel"}
				warningIcon={CrossRedIcon}
			/>
		</>
	);
}
