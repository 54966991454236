import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ExportallIcon } from "assests/images";
import { useNavigate } from "react-router-dom";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import {
	IRequestDataSearchCriteria,
	IHistoryRequestData,
	IHistorySearchFields,
	IHistoryRequest,
} from "utility/interfaces";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { RequestDataTable } from "components";
import { createCommonAPICall, exportToExcel } from "utility/helpers";
import historyService from "services/history-service";
import moment from "moment";
import { toast } from "react-toastify";

const RequestData: React.FC = () => {
	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
	const [requestDataPageInfo, setRequestDataPageInfo] =
		React.useState<IHistoryRequestData | null>(null);

	const [searchCriteria, setSearchCriteria] =
		useState<IRequestDataSearchCriteria>({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.descending,
			SortBy: "CaseClosedDate",
			SearchBy: "",
			status: 0,
			requestType: 0,
			providerName: null,
			email: null,
			phoneNumber: null,
			fromDateOfService: null,
			patientName: null,
			toDateOfService: null,
			cancellationReason: null,
			notes: null,
		});

	const [searchFields, setSearchFields] = useState<IHistorySearchFields>({
		requestStatus: [],
		requestType: [],
		caseTags: [],
	});

	// Handled events and functions
	const handleOnSearchCriteriaChange = (
		newSearchCriteria: IRequestDataSearchCriteria
	) => {
		setSearchCriteria(newSearchCriteria);
	};
	const getHistoryPageGrids = async () => {
		let fromDateOfService = searchCriteria.fromDateOfService
			? new Date(searchCriteria.fromDateOfService).toLocaleString("en-US")
			: null;
		let toDateOfService = searchCriteria.toDateOfService
			? new Date(searchCriteria.toDateOfService).toLocaleString("en-US")
			: null;
		const requestBody: IRequestDataSearchCriteria = {
			...searchCriteria,
			fromDateOfService:
				fromDateOfService == "Invalid Date" ? null : fromDateOfService,
			toDateOfService:
				toDateOfService == "Invalid Date" ? null : toDateOfService,
		};
		const data = await createCommonAPICall({
			requestBody,
			apiService: historyService.getRequestDataGrid,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			setRequestDataPageInfo({
				requests: data.data.requests,
				totalRecords: data.data.totalRecords,
			});
			setSearchFields({
				requestStatus: data.data.requestStatus,
				requestType: data.data.requestType,
				caseTags: data.data.caseTags,
			});
		}
	};

	const handleClickExport = async () => {
		const exportData: any = [];

		var data = await createCommonAPICall({
			requestBody: searchCriteria,
			apiService: historyService.exportAllCases,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			data.data?.forEach((request: IHistoryRequest) => {
				exportData.push({
					"Patient Name": request.patientName,
					Email: request.email,
					"Phone Number": request.phoneNumber,
					Address: request.address,
					"Zip Code": request.zipCode,
					"Date Of Service": request.dateOfService
						? moment(request.dateOfService).format("MMM D, YYYY")
						: "-",
					"Request Status": request.transactionStatus,
					Status: request.requestStatus,
					"Requested Date": request.requestedDate,
					"Physician Name": request.physicianName,
					"Admin Notes": request.adminNotes,
					"Physician Notes": request.physicianNotes,
					"Client Notes": request.clientNotes,
					"Cancellation Reasons": request.cancellationReasons,
					"Cancelled by provider notes": request.cancelledByProviderNotes,
				});
			});

			const columnWiseWidth = [
				{ width: 25 }, // Patient Name
				{ width: 35 }, // Email
				{ width: 20 }, // Phone Number
				{ width: 50 }, // Address
				{ width: 20 }, // Zip Code
				{ width: 30 }, // Date Of Service
				{ width: 20 }, // Transection Status
				{ width: 20 }, // Status
				{ width: 30 }, // Requested Date
				{ width: 20 }, // Physician Name
				{ width: 30 }, // Admin Notes
				{ width: 30 }, // Physician Notes
				{ width: 30 }, // Client Notes
				{ width: 30 }, // Cancellation Reasons
				{ width: 30 }, // Cancelled by provider notes
			];
			if (exportData.length > 0)
				exportToExcel(
					exportData,
					columnWiseWidth,
					`RequestData_${moment(new Date()).format("MM/DD/YYYY HH:mm:ss")}`
				);
			else toast.error("No record(s) here to export");
		}
	};

	//Page level local variable
	const navigate = useNavigate();

	// useEffects
	useEffect(() => {
		getHistoryPageGrids();
	}, [searchCriteria]);

	return (
		<>
			<Box>
				<div className="chatbox-overlay"></div>
				<main className="main-content">
					<div
						className="overlay"
						onClick={() => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="tab-item-header">
						{/* <Typography variant="h2">Request Data</Typography> */}
						<Typography variant="h2">Search Records</Typography>
						<Box className="tab-button-groups">
							<Button
								disabled={requestDataPageInfo?.totalRecords === 0}
								variant="contained"
								onClick={handleClickExport}
							>
								<img src={ExportallIcon} alt="send history" />
								<span className="button-link">Export Data To Excel</span>
							</Button>
						</Box>
					</Box>
					<RequestDataTable
						handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
						searchCriteria={searchCriteria}
						rowData={requestDataPageInfo}
						searchFields={searchFields}
						getRequestData={getHistoryPageGrids}
					/>
				</main>
			</Box>
		</>
	);
};

export default RequestData;
